import { ProjectSchema } from "@ddb/environment-context-service";
import { Store } from "@tanstack/store";
import { DdbEnvironment } from "../shared/ddb";

export enum QueryFormat {
  // Template = "template",
  Manual = "manual",
}

interface Query {
  job_number: string;
  environment: DdbEnvironment;
  format: QueryFormat;
}

export interface StoreSchema {
  step: number;
  environment: DdbEnvironment;
  queryFormat: QueryFormat;
  project?: ProjectSchema;
  queries: Array<Query>;
}

export const STORE = new Store<StoreSchema>({
  step: 0,
  environment: DdbEnvironment.Develop,
  queryFormat: QueryFormat.Manual,
  project: undefined,
  // TODO: load queries from storage
  queries: [],
});
