import * as React from "react";
import {
  useToastController,
  createTableColumn,
  Button,
  Toast,
  ToastTitle,
  ToastBody,
  TableColumnDefinition,
} from "@fluentui/react-components";
import {
  DataGridBody,
  DataGrid,
  DataGridRow,
  DataGridHeader,
  DataGridCell,
  DataGridHeaderCell,
} from "@fluentui-contrib/react-data-grid-react-window";
import { useStore } from "@tanstack/react-store";
import { useQuery } from "@tanstack/react-query";
import { AssetSchema, ParameterSchema } from "@ddb/parameter-service";
import { parameterService } from "../../shared/ddb";
import { STORE } from "../store";
import { EXCEL_FUNCTION, createFunction } from "../functions";
import { writeValuesToCells } from "../excel";

type AssetSchemaAndParameterSchema = [Array<AssetSchema>, Array<ParameterSchema>];

const SelectParamters: React.FC = () => {
  const { dispatchToast } = useToastController("global-taoster");
  const [currentParam, setCurrentParam] = React.useState<ParameterSchema | undefined>();
  const project = useStore(STORE, ({ project }) => project);
  const environment = useStore(STORE, ({ environment }) => environment);
  const parameterQuery = useQuery<AssetSchemaAndParameterSchema>({
    queryKey: ["parameters"],
    retry: false,
    queryFn: async () => {
      try {
        if (!project) throw new Error("No project selected");
        const { project_id } = project;
        const env = parameterService(environment);
        const assets = await env.getAssets({ projectId: [project_id] });
        const parameters = await env.getAllParameters({
          assetId: assets.data?.assets?.map((a) => a.id),
          projectId: [project_id],
        });
        setCurrentParam(parameters.data.parameters[0]);
        return [assets.data.assets, parameters.data.parameters];
      } catch (error) {
        dispatchToast(
          <Toast>
            <ToastTitle>Error</ToastTitle>
            <ToastBody subtitle="Error">
              Failed to get parameters for {project?.short_title} ({project?.number})
            </ToastBody>
          </Toast>,
          { intent: "error" }
        );
        throw error;
      }
    },
  });

  const [assets, paramaters] = parameterQuery.data || [[], []];
  const columns: TableColumnDefinition<AssetSchema>[] = [
    createTableColumn<AssetSchema>({
      columnId: "asset_type",
      renderHeaderCell: () => <DataGridHeaderCell>Type</DataGridHeaderCell>,
      renderCell: ({ asset_type }) => <DataGridCell>{asset_type.name}</DataGridCell>,
    }),
    createTableColumn<AssetSchema>({
      columnId: "name",
      renderHeaderCell: () => <DataGridHeaderCell>Name</DataGridHeaderCell>,
      renderCell: ({ name }) => <DataGridCell>{name}</DataGridCell>,
    }),
    createTableColumn<AssetSchema>({
      columnId: "path",
      renderHeaderCell: () => <DataGridHeaderCell>Path</DataGridHeaderCell>,
      renderCell: ({ name }) => <DataGridCell>{`${project?.number}/${name}`}</DataGridCell>,
    }),
    createTableColumn<AssetSchema>({
      columnId: "value",
      renderHeaderCell: () => <DataGridHeaderCell>Value</DataGridHeaderCell>,
      renderCell: ({ id }) => {
        if (currentParam?.parents.map(({ id }) => id).includes(id)) {
          return <DataGridCell>{currentParam?.selected_entry?.values.at(0)?.value}</DataGridCell>;
        } else {
          return <DataGridCell>-</DataGridCell>;
        }
      },
    }),
    createTableColumn<AssetSchema>({
      columnId: "unit",
      renderHeaderCell: () => <DataGridHeaderCell>Unit</DataGridHeaderCell>,
      renderCell: ({ id }) => {
        if (currentParam?.parents.map(({ id }) => id).includes(id)) {
          return <DataGridCell>{currentParam?.selected_entry?.values.at(0)?.unit?.symbol}</DataGridCell>;
        } else {
          return <DataGridCell>-</DataGridCell>;
        }
      },
    }),
  ];

  const handleInsertClick = () => {
    const functionStrings: string[][] = [];
    functionStrings.push([
      EXCEL_FUNCTION.ASSET.ASSET_TYPE_NAME,
      EXCEL_FUNCTION.ASSET.ASSET_NAME,
      EXCEL_FUNCTION.PARAMETER.PARAMETER_TYPE_NAME,
      EXCEL_FUNCTION.PARAMETER.PARAMETER_VALUE,
      EXCEL_FUNCTION.PARAMETER.PARAMETER_UNIT_SYMBOL,
      EXCEL_FUNCTION.PARAMETER.PARAMETER_STATUS,
    ]);
    for (const param of paramaters) {
      if (param) {
        const asset = assets.find((asset) => asset.id === param.parents.map(({ id }) => id)[0]);
        if (asset) {
          functionStrings.push([
            createFunction(environment, EXCEL_FUNCTION.ASSET.ASSET_TYPE_NAME, asset.id),
            createFunction(environment, EXCEL_FUNCTION.ASSET.ASSET_NAME, asset.id),
            createFunction(environment, EXCEL_FUNCTION.PARAMETER.PARAMETER_TYPE_NAME, param.id),
            createFunction(environment, EXCEL_FUNCTION.PARAMETER.PARAMETER_VALUE, param.id, "latest"),
            createFunction(environment, EXCEL_FUNCTION.PARAMETER.PARAMETER_UNIT_SYMBOL, param.id),
            createFunction(environment, EXCEL_FUNCTION.PARAMETER.PARAMETER_STATUS, param.id),
          ]);
        }
      }
    }
    writeValuesToCells(functionStrings);
  };

  const handleNextParamClick = () => {
    const currentIndex = paramaters.findIndex((param) => param.id === currentParam?.id);
    const nextParam = paramaters[currentIndex + 1];
    if (nextParam) {
      setCurrentParam(nextParam);
    }
  };

  const handlePrevParamClick = () => {
    const currentIndex = paramaters.findIndex((param) => param.id === currentParam?.id);
    const prevParam = paramaters[currentIndex - 1];
    if (prevParam) {
      setCurrentParam(prevParam);
    }
  };

  return (
    <div style={{ display: "grid", gap: "1rem" }}>
      <h2>Parameters</h2>

      <span>
        <strong>Total:</strong> assets ({assets.length}) paramaters ({paramaters.length})
      </span>

      <span>
        <strong>Param Name:</strong> {currentParam?.parameter_type.name}
      </span>

      <DataGrid items={assets} columns={columns}>
        <DataGridHeader>
          <DataGridRow<AssetSchema>>{({ renderHeaderCell }) => renderHeaderCell()}</DataGridRow>
        </DataGridHeader>
        <DataGridBody<AssetSchema> itemSize={50} height={400}>
          {({ item }) => <DataGridRow<AssetSchema> key={item.id}>{({ renderCell }) => renderCell(item)}</DataGridRow>}
        </DataGridBody>
      </DataGrid>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handlePrevParamClick}>Previous Parameter</Button>
        <Button onClick={handleNextParamClick}>Next Parameter</Button>
      </div>

      <Button appearance="primary" onClick={handleInsertClick}>
        Insert Table Into Excel
      </Button>
    </div>
  );
};

export default SelectParamters;
