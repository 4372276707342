import * as React from "react";
import { useIsFetching } from "@tanstack/react-query";
import { useStore } from "@tanstack/react-store";
import {
  makeStyles,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
  ProgressBar,
  Tooltip,
  isTruncatableBreadcrumbContent,
  truncateBreadcrumbLongName,
  Toaster,
} from "@fluentui/react-components";
import { STORE } from "../store";
import SavedQueries from "./SavedQueries";
import ProjectSelect from "./ProjectSelect";
import SelectParamters from "./SelectParamters";

const useStyles = makeStyles({
  layout: { display: "grid", gap: "1rem", padding: "1rem" },
  form: { display: "grid", gap: "0.5rem", background: "rgb(212, 66, 20)", padding: "0.5rem", borderRadius: "0.25rem" },
  tooltip: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});

interface Step {
  name: (() => string) | string;
}

const STEPS: Step[] = [
  { name: "Saved Queries" },
  {
    name: () => {
      const { project, environment } = useStore(STORE, (state) => state);
      return project
        ? `ENV: ${environment.toLocaleUpperCase()} Project: ${project.short_title} (${project.number})`
        : "Select Env & Project";
    },
  },
  { name: "Select Paramters" },
];
function renderStep(entry: Step, isLastItem: boolean, index: number) {
  const name = typeof entry.name === "function" ? entry.name() : entry.name;
  const isCurrent = useStore(STORE, ({ step }) => step === index);

  return (
    <React.Fragment key={`item-${index}`}>
      {isTruncatableBreadcrumbContent(name, 30) ? (
        <BreadcrumbItem>
          <Tooltip withArrow content={name} relationship="label">
            <BreadcrumbButton current={isCurrent}>{truncateBreadcrumbLongName(name)}</BreadcrumbButton>
          </Tooltip>
        </BreadcrumbItem>
      ) : (
        <BreadcrumbItem>
          <BreadcrumbButton current={isCurrent}>{truncateBreadcrumbLongName(name)}</BreadcrumbButton>
        </BreadcrumbItem>
      )}

      {!isLastItem && <BreadcrumbDivider />}
    </React.Fragment>
  );
}

const Layout: React.FC = () => {
  const styles = useStyles();
  const loading = useIsFetching();
  const currentStep = useStore(STORE, ({ step }) => step);

  return (
    <div className={styles.layout}>
      <Toaster toasterId="global-toaster" />
      {loading > 0 && <ProgressBar thickness="large" />}

      <Breadcrumb size="small">
        {STEPS.map((entry, index) => renderStep(entry, index === STEPS.length - 1, index))}
      </Breadcrumb>

      {currentStep === 0 && <SavedQueries />}
      {currentStep === 1 && <ProjectSelect />}
      {currentStep === 2 && <SelectParamters />}
    </div>
  );
};

export default Layout;
