import {
  Configuration,
  ConfigurationParameters,
  Environment,
  EnvironmentContextApi,
} from "@ddb/environment-context-service";
import { ParameterApi } from "@ddb/parameter-service";
import { msal } from "./msal";

export enum DdbEnvironment {
  Develop = "develop",
  Sandbox = "sandbox",
  Production = "production",
}

export function getConfiguration(environment: DdbEnvironment, version: string): Configuration {
  const params: ConfigurationParameters = {
    basePath: Environment[environment],
    accessToken: (_: string, scopes: string[]) => msal.ssoGetToken(scopes),
    baseOptions: {
      headers: {
        Version: version,
        "App-Client-Type": "Excel Add-in",
      },
    },
  };
  const config = new Configuration(params);
  return config;
}

export function environmentContextService(environment: DdbEnvironment): EnvironmentContextApi {
  const config = getConfiguration(environment, "2");
  return new EnvironmentContextApi(config);
}

export function parameterService(environment: DdbEnvironment): ParameterApi {
  const config = getConfiguration(environment, "1");
  return new ParameterApi(config);
}

export function validateEnvironment(environment: string): environment is DdbEnvironment {
  return ["develop", "sandbox", "production"].includes(environment);
}

export async function* unpaginate<T, O>(
  requestFunction: (after: string | undefined) => Promise<T>,
  getInner: (data: T) => Iterable<O>,
  getAfter: (data: T) => string | undefined
) {
  let after: string | undefined = undefined;
  do {
    const data = await requestFunction(after);
    yield* getInner(data);
    after = getAfter(data);
  } while (after);
}
