import { DdbEnvironment } from "../shared/ddb";

export const EXCEL_FUNCTION = {
  ASSET: {
    ASSET_NAME: "ASSET.ASSET_NAME",
    PARENT_ASSET_ID: "ASSET.PARENT_ASSET_ID",
    CHILD_IDS: "ASSET.CHILD_IDS",
    ASSET_TYPE_ID: "ASSET.ASSET_TYPE_ID",
    ASSET_TYPE_NAME: "ASSET.ASSET_TYPE_NAME",
  },
  PARAMETER: {
    PARAMETER_TYPE_NAME: "PARAMETER.PARAMETER_TYPE_NAME",
    PARAMETER_VALUE: "PARAMETER.PARAMETER_VALUE",
    PARAMETER_SOURCE_ID: "PARAMETER.PARAMETER_SOURCE_ID",
    PARAMETER_STATUS: "PARAMETER.PARAMETER_STATUS",
    PARAMETER_UNIT_SYMBOL: "PARAMETER.PARAMETER_UNIT_SYMBOL",
  },
} as const;

export type ExcelFunction =
  | (typeof EXCEL_FUNCTION.PARAMETER)[keyof typeof EXCEL_FUNCTION.PARAMETER]
  | (typeof EXCEL_FUNCTION.ASSET)[keyof typeof EXCEL_FUNCTION.ASSET];

export function createFunction(
  environment: DdbEnvironment,
  excelFunction: ExcelFunction,
  ...args: Array<string | number>
): string {
  return `=DDB.${excelFunction}("${environment}", ${args
    .map((arg) => {
      if (typeof arg === "string") {
        return `"${arg}"`;
      }

      return arg;
    })
    .join(", ")})`;
}
