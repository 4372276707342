/* global Excel */

export function wrtieValueToCells(value: string) {
  return Excel.run((context) => {
    const range = context.workbook.getActiveCell();
    range.values = [[value]];
    return context.sync();
  });
}

export function writeValuesToCells(values: string[][]) {
  return Excel.run((context) => {
    const range = context.workbook
      .getActiveCell()
      .getResizedRange(values.length - 1, values.map((row) => row.length).reduce((a, b) => Math.max(a, b), 0) - 1);

    range.values = values;

    return context.sync();
  });
}
